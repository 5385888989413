import React, { useEffect, useState, useContext } from "react";
import S3 from 'react-aws-s3';
import { CurrentUserContext } from "../App";
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader.js';
import './CDP_upddate.css';
import AddressCom from './SubComponents/AddressCom';
import PhoneCom from './SubComponents/PhoneCom';

export default function DNDNodeClickPopLab({ openCDP, setOpenCDP }) {
    window.Buffer = window.Buffer || require("buffer").Buffer;
    const { auth_token, setOpenLogin, setSelectedBtn, base_url, urID, setURid, cdpDetails, setCdpDetails, headerData } = useContext(CurrentUserContext);
    const [loader, setLoader] = useState(false);
    const [header, setHeader] = useState(`CDP Registration`);
    const [dataa, setData] = useState(cdpDetails);
    const [fieldValidation, setFieldValidation] = useState({});
    const [err, setErr] = useState(false);
    const [openn, setOpen] = useState(openCDP);
    const handleOpen = () => setOpen(true);
    const [edit, setEdit] = useState(false);
    const [propmptFlag, setPromptFlag] = useState(false);
    const handleClose = () => {
        setOpenCDP(false);
        if (!edit) {
            finalClose();
        } else {
            setPromptFlag(true);
        }
    };
    function finalClose() {
        setEdit(false);
        setOpen(false);
        setOpenCDP(false);
    }

    useEffect(() => {
        if (cdpDetails?.id == null) { // Default edit true based on the id of the nodes
            onEdit();
        }
    }, []);

    useEffect(() => {
        setData(cdpDetails);
        setFieldValidation(cdpDetails);
    }, [cdpDetails]);

    const handleImageClickHH = (key) => {
        document.getElementById(`fileInputHH${key}`).click();
    };

    // const bucketName = process.env.REACT_APP_BUCKET_NAME;
    // const region = process.env.REACT_APP_REGIN;
    // const accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
    // const secretAccessKey = process.env.REACT_APP_SECRET_ACCESS_KEY;
    // const uploadFile = async (file) => {
    //     const ReactS3Client = new S3({
    //         bucketName: bucketName,
    //         region: region,
    //         accessKeyId: accessKeyId,
    //         secretAccessKey: secretAccessKey
    //     });
    //     try {
    //         const data = await ReactS3Client.uploadFile(file, file.name);
    //         const url = data.location;
    //         console.log("URL is ", url);
    //         return url;
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };
    const getPresignedUrlFromServer = async (name) => {
        try {
            const response = await fetch(`${base_url}/api/v1/master_data/generate_presigned_url_for_upload?object_key=${name}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': auth_token
                },
            });
            if (!response.ok) {
                throw new Error(`Server error: ${response.statusText}`);
                setLoader(false);
                return;
            }
            const data = await response.json();
            console.log("presigned url", data)
            return data.presigned_url;
        } catch (error) {
            setLoader(false);
            console.error('Error fetching pre-signed URL:', error);
            throw error;
        }
    };

    const uploadFile = async (file) => {
        setLoader(true);
        const presignedUrl = await getPresignedUrlFromServer(file.name);
        if (presignedUrl == undefined) return;
        if (!file) {
            return;
        }
        try {

            const uploadResponse = await fetch(presignedUrl, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": file.type,
                },
            });
            if (uploadResponse.ok) {
                console.log(`${file.name} uploaded successfully.`);
                setLoader(false);
                return presignedUrl.split('?')[0];
            } else {
                toast.error(uploadResponse.statusText, {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoader(false);
            }
        } catch (error) {
            console.error(`Error uploading ${file.name}:`, error);
            setLoader(false);
        }
    };

    function capitalizeEachWord(inputString) {
        return inputString.replace(/\b\w/g, match => match.toUpperCase());
    }

    const keySequence = [
        'name',
        'desc',
        // 'domain_link',
        'web_logo_url',
        // 'app_logo_url',
        // 'hme_bgrnd_img',
    ];

    function matchLabel(key) {
        switch (key) {
            case 'name':
                return 'CDP Name';
            case 'desc':
                return 'Description';
            case 'domain_link':
                return 'Domain Link';
            case 'web_logo_url':
                return 'Web logo';
            case 'app_logo_url':
                return 'App logo';
            case 'hme_bgrnd_img':
                return 'Login page image';
            case 'category':
                return 'Category';
            case 'lab_name':
                return 'Lab Name';
            case 'facility_type_master_id':
                return 'Facility Type Master';
            case 'phn_nmbr_typ':
                return 'Phone Number Type';
            case 'nmbr':
                return 'Phone Number';
            case 'rgstrton_no':
                return 'Registration Number';
            case 'rgstrton_yr':
                return 'Registration Year';
            case 'effctv_start_dt':
                return 'Effective Date';
            case 'patient_id_format':
                return 'Patient ID Format';
            case 'cmmts':
                return 'Comments';
            case 'logo':
                return 'Logo';
            case 'wbst_url':
                return 'Web site URL';
            case 'app_link_url':
                return 'App Link URL';
            case 'header_img_url':
                return 'Header Image';
            case 'header_img_height':
                return 'Header Height';
            case 'footer_img_url':
                return 'Footer Image';
            case 'footer_img_height':
                return 'Footer Height';
            case 'address':
                return 'Address';
            default:
                return key.replace(/_/g, ' ');
        }
    }

    function matchLabelDescription(key) {
        switch (key) {
            case 'hme_bgrnd_img':
                return 'Login page background image';
            case 'lab_name':
                return 'Name of the Lab. This is the unique identifier of the lab across the application.';
            case 'facility_type_master_id':
                return 'Type of Facility. This field decides the options the user will have. For example, IP facility will have option to admit a patient while OP facility will not have that option.';
            case 'phn_nmbr_typ':
                return 'Phone Number Type';
            case 'nmbr':
                return 'Phone Number';
            case 'rgstrton_no':
                return 'Registration Number of the Facility. Check government documents';
            case 'rgstrton_yr':
                return 'Registration Year of the Facility. Check government documents';
            case 'effctv_start_dt':
                return 'Effective Date';
            case 'patient_id_format':
                return 'Patient ID Format';
            case 'cmmts':
                return 'Comments';
            case 'header_img_url':
                return 'Header picture that is used for invoices and prescription';
            case 'header_img_height':
                return 'Change the number between 20-200 and check preview.';
            case 'footer_img_url':
                return 'Footer picture that is used for invoices and prescription';
            case 'footer_img_height':
                return 'Change the number between 20-200 and check preview.';
            case 'address':
                return 'Address';
            case 'address_detail_id':
                return 'Address Detail Id';
            case 'is_day_clinic':
                return 'Select if the OP clinic has beds and can admit patients.';
            case 'is_active':
                return 'Select for all active facilties';
            default:
                return key.replace(/_/g, ' ');
        }
    }

    const handleChangeWS = (key, value) => {
        setData(prevState => ({
            ...prevState,
            [key]: value
        }));
        setFieldValidation(prevState => ({
            ...prevState,
            [key]: !!value
        }));
    };
    async function createCDP(data) {
        setLoader(true);
        const post_data = { user_id: urID, ...data };
        const { address, contact, ...restObject } = post_data;
        const { id: addressId, ...restObjectAddress } = address;
        const { id: contactId, ...restObjectContact } = contact;
        const result = { ...restObject, ...restObjectAddress, ...restObjectContact };
        console.log("Post data for cdp create", urID, result);
        // return;
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(result),
            redirect: "follow"
        };
        try {
            const response = await fetch(`${base_url}/api/v1/master_data/create_cdp`, requestOptions);
            const result = await response.json();
            console.log("Response of the CDP creation", response);
            if (response.status == 200) {
                toast.success("CDP created please login with your gmail/number.", {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    setOpenLogin(true);
                    setSelectedBtn(3);
                    setOpenCDP(false);
                    setLoader(false);
                }, 1000)

            }
            console.log("Result of the CDP creation", result);

            return result;
        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
            throw error; // Optionally rethrow the error to be handled by the caller
        }
    }
    async function handleSubmitCDP() {
        if (!fieldValidation['name']) {
            setErr(true);
            if (dataa.id == null && dataa.address.area == "" && dataa.address.address_line1 == "" && dataa.address.pin_code == "") {
                toast.error("Address is required for registering a new CDP.", {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            if (dataa.id == null && dataa.contact.nmbr == "" && dataa.contact.email == "") {
                toast.error("Contact detail is required for registering a new CDP.", {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return;
        }
        if (dataa.id == null && dataa.address.area == "" && dataa.address.address_line1 == "" && dataa.address.pin_code == "") {
            toast.error("Address is required for registering a new CDP.", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        if (dataa.id == null && dataa.address.area == "" && dataa.address.address_line1 == "" && dataa.address.pin_code == "") {
            toast.error("Address is required for registering a new CDP.", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        if (dataa.id == null && dataa.contact.nmbr == "" && dataa.contact.email == "") {
            toast.error("Contact detail is required for registering a new CDP.", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        createCDP(dataa);
    }
    const [temF, setTemF] = useState(false);
    const [phnF, setPhoneF] = useState(false);
    return (<>
        {temF && <AddressCom type={'CDP'} dataa={dataa} setData={setData} setTemF={setTemF} temF={temF} />}
        {phnF && <PhoneCom dataa={dataa} setData={setData} setPhoneF={setPhoneF} phnF={phnF} />}
        <div className="cdp_containter">
            {loader && <div className='addressContainer'><Loader /></div>}
            <div className="cdp_updatebox">
                <h3 className="cdp_heading" style={{
                    // background: `linear-gradient(to right, ${headerData?.primary_color} 0%, ${headerData?.secondary_color} 100%)`
                    background: headerData?.primary_color
                }}>
                    <span>{''}</span><span id="popupH">{`${header}`} </span>
                    <span className="cdp_closebtn" style={{ color: headerData?.primary_color }} onClick={handleClose}>x</span>
                </h3>

                <div className="form_area">
                    <div id="rightCornerPA">
                        <span
                            aria-label="convert"
                            title={'Add/Update address'}
                        >
                            <img className="addphone" src={'https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/1721716802378.png'}
                                onClick={() => {
                                    setTemF(true)
                                }}
                            ></img>
                        </span>
                        <span
                            aria-label="convert"
                            title={'Add/Update Number'}
                        >
                            <img className="addphone" src={'https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/1721716824511.png'}
                                onClick={() => {
                                    setPhoneF(true)
                                }}
                            ></img>
                        </span>
                    </div>
                    {keySequence.map((key) => {
                        if (['web_logo_url', 'app_logo_url', 'hme_bgrnd_img'].includes(key)) {
                            return (
                                <div key={key} className="eachContent" style={{
                                    borderBottom: '1px solid gray', borderRadius: '2.7px', height: '49px'
                                }}>
                                    <label id={'HospitalLabel'}>
                                        <span>{matchLabel(key)}</span>
                                    </label>
                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                                        {dataa[key] && (
                                            <div style={{ marginTop: "0px", display: 'inline', width: 'auto' }}>
                                                <img
                                                    src={dataa[key]}
                                                    alt=""
                                                    style={{ height: "30px", maxWidth: '95%' }}
                                                />
                                            </div>
                                        )}
                                        {edit && (
                                            <div style={{ display: "flex" }}>
                                                <span style={{ position: 'relative' }}>
                                                    {dataa[key] && (
                                                        <span
                                                            style={{ color: 'red', position: 'absolute', top: '-20px', left: '0px', cursor: 'pointer', fontSize: '18px' }}
                                                            onClick={() => {
                                                                handleChangeWS(key, null);
                                                            }}>x</span>
                                                    )}
                                                    <img
                                                        style={{
                                                            marginLeft: '3px', margin: '0px 2px', display: 'inline',
                                                            height: "32px", borderRadius: "50%", cursor: "pointer"
                                                        }}
                                                        alt="add file"
                                                        onClick={() => handleImageClickHH(key)}
                                                        src={"https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/1721720451270.png"}
                                                    />
                                                </span>
                                                <input
                                                    type="file"
                                                    id={`fileInputHH${key}`}
                                                    style={{
                                                        display: "none",
                                                    }}
                                                    accept="image/png, image/jpeg"
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const fileExtension = file.name.split('.').pop();
                                                        const uniqueFileName = `${Date.now()}.${fileExtension}`;
                                                        const uniqueFile = new File([file], uniqueFileName, { type: file.type });
                                                        uploadFile(uniqueFile)
                                                            .then(url => {
                                                                handleChangeWS(key, url);
                                                            })
                                                            .catch(error => {
                                                                console.error("Error uploading file:", error);
                                                            });
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        } else if (key === 'name') {
                            return (
                                <div key={key} className="eachContent">
                                    <label>{matchLabel(key)}<p className="star">*</p></label>
                                    <input
                                        type="text"
                                        value={dataa[key] || ''}
                                        onChange={(e) => handleChangeWS(key, capitalizeEachWord(e.target.value))}
                                        disabled={!edit}
                                        style={{
                                            borderBottom: err ? (fieldValidation[key] && dataa[key]) ? '1px solid gray' : '2px solid red' : '1px solid gray'
                                        }}
                                    />
                                    {err && !dataa[key] && <span style={{ display: 'block', position: 'relative', color: 'red' }}>{'Fill this section'}</span>}

                                </div>)
                        } else {
                            return (
                                <div key={key} className="eachContent">
                                    <label>{matchLabel(key)}</label>
                                    <input
                                        type="text"
                                        value={dataa[key] || ''}
                                        onChange={(e) => handleChangeWS(key, e.target.value)}
                                        disabled={!edit}
                                    />
                                </div>
                            );
                        }
                    })}

                </div>
                <div id="fixed_footerr" style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 8px', marginTop: '0px', backgroundColor: headerData?.secondary_color }}>
                    <button className="BtnC" style={{ background: '#FF852E !important' }} type="button"
                        onClick={handleClose}>Close</button>
                    {edit === true ?
                        <button className="BtnS" type="submit" onClick={handleSubmitCDP}>Submit</button> :
                        <button className="BtnS" type="submit" onClick={() => setEdit(true)}>Edit</button>
                    }
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div >
    </>
    );

    function onEdit() {
        setEdit(true);
    }
}




