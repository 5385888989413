import React, { useContext, useState, useEffect } from "react";
import { CurrentUserContext } from '../App.js';
import { ToastContainer, toast } from 'react-toastify';
import './Loginpage.css';
export default function Loginpage({ openLogin, setOpenLogin }) {
    const { setAuthToken, selectBtn, setSelectedBtn, urID, setURid, openCDP, setOpenCDP, headerData, setData, wLoader, setWLoader, base_url } = useContext(CurrentUserContext);
    const [loginpageData, setLoginPageData] = useState({});
    // const [selectBtn, setSelectedBtn] = useState(0);
    const [rediObject, setRediObject] = useState({ cdp_id: '', token: '', role_id: '22', base_url: base_url, public_key: null })
    const [formData, setFormData] = useState({
        email: '', password: '', number: '', countryCode: '+91',
        firstname: '', lastname: '', gender: '0', age: '', otp: '',
        newpassword: '', cnewpassword: '',
    });
    const [OTP, setOTP] = useState('');
    const [errmsg, setErrmessage] = useState("");

    useEffect(() => {
        const data = headerData?.website_sections && headerData?.website_sections.length >= 1 &&
            headerData?.website_sections?.filter(obj => obj?.w_section_type_master_id === 44).length > 0 &&
            headerData?.website_sections?.filter(obj => obj?.w_section_type_master_id === 44)[0].content_details &&
            headerData?.website_sections?.filter(obj => obj?.w_section_type_master_id === 44)[0].content_details.length > 0 &&
            headerData?.website_sections?.filter(obj => obj?.w_section_type_master_id === 44)[0].content_details[0];
        setLoginPageData(data);
    }, [headerData])

    const updateFormData = (key, value) => {
        setErrmessage('');
        setFormData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };
    const countryCodes = [
        { code: '+1', label: 'United States' },
        { code: '+44', label: 'United Kingdom' },
        { code: '+91', label: 'India' },
        // Add more country codes as needed
    ];
    function calculateDOBFromAge(age) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const dobYear = currentYear - age;

        const dobDate = new Date(currentDate);
        dobDate.setFullYear(dobYear);
        const day = dobDate.getDate().toString().padStart(2, '0');
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[dobDate.getMonth()];
        const shortYear = dobYear.toString().slice(-2);

        return `${day}-${month}-${shortYear}`;
    }
    const [user, setUserData] = useState({});
    async function updatepassword() {
        setWLoader(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ "user": { "password": formData.newpassword, "password_confirmation": formData.cnewpassword } });
        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${base_url}/api/v1/reset_passwords/${user.id}`, requestOptions);
            const result = await response.json();
            console.log("result", response.status);
            setSelectedBtn(0); // go to new page
            setWLoader(false);

            //REDIRECT
        } catch (error) {
            console.error(error);
            setWLoader(false);
        }
    }
    async function verify_otp() {
        setWLoader(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ "user": { "user_id": user.id, "otp": formData.otp } });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${base_url}/api/v1/verify_otp`, requestOptions);
            const result = await response.json();
            console.log("result", response.status);
            setSelectedBtn(5); // go to new page
            setWLoader(false);

            //REDIRECT
        } catch (error) {
            console.error(error);
            setWLoader(false);
        }
    }
    async function forgotOTP() {
        setWLoader(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ "user": { "login": formData.email || formData.number } });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${base_url}/api/v1/reset_passwords`, requestOptions);
            const result = await response.json();
            console.log("result", response.status);
            setForgotpass(true);
            if (response.status == 200) {
                setSelectedBtn(4);
                setUserData(result.user)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

            setWLoader(false);

            //REDIRECT
        } catch (error) {
            console.error(error);
            setWLoader(false);
        }
    }
    async function login() {
        setWLoader(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "login": formData.email || formData.number,
            "password": formData.password,
            "fcm_token": "",
            "device_token": "",
            "device_type": "",
            "location_program_maping_master_id": "1"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${base_url}/api/v1/login`, requestOptions);
            const result = await response.json();
            console.log("result", response.status);
            if (response.status == 200) {
                // window.location.href = `${base_url}/home?login=${formData.email || formData.number}&password=${formData.password}`;
                window.location.href = `https://master.d2mp9977g7at58.amplifyapp.com/cdp_master?cdp_id=${result.cdp_id}&token=${result.web_auth_token}&role_id=${22}&base_url=${base_url}&public_key=${null}`;
                setOpenLogin(!openLogin);
                setTimeout(() => {
                    setWLoader(false);
                }, 110000)
            } else {
                setErrmessage(result.message);
                setWLoader(false);
            }

            //REDIRECT
        } catch (error) {
            console.error(error);
            setWLoader(false);
        }
    }
    async function createUserAccount() {
        setWLoader(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "gndr": formData.gender,
            "nmbr": formData.number,
            "frst_nm": formData.firstname,
            "lst_nm": formData.lastname,
            "email": formData.email,
            "dob": calculateDOBFromAge(formData.age),
            "addrss": "",
            "hght_ft": "",
            "hght_in": "",
            "cntry_code": formData.countryCode,
            "device_type": "",
            "location_program_maping_master_id": "1"
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${base_url}/api/v1/master_data/create_cdp_admin`, requestOptions);
            const result = await response.json();

            if (response.status == 409) {
                toast.success(result.message, {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); //here we will update the cdp page and save the user id in System
                setSelectedBtn(0);
            }
            if (response.status == 200) {
                toast.success(result.message, {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setURid(result.user.id);
                setAuthToken(result.user.web_auth_token);
                setOpenLogin(false);
                setOpenCDP(true);  //here we will update the cdp page and save the user id in System
                // setSelectedBtn(0);
            } else if (response.status == 500) {
                toast.error("Error while creating pls check console", {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            console.log(result);
            setWLoader(false);
        } catch (error) {
            console.error(error);
            setWLoader(false);
        }
    }
    function loginwithOTP() {
        setWLoader(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ session: { login: formData.email } }),
            redirect: "follow"
        };
        fetch(`${base_url}/api/v1/login_otp`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                toast.warning(result.message, {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log(result);
                setAuthToken(result.web_auth_token)
                setOTP(result.otp_code);
                if (result?.message?.includes("OTP has been sent successfully to your number/email")) {
                    setCountdown(30);
                    setSelectedBtn(4);  //here we are redirection to verify section
                }
                setRediObject({ 'cdp_id': result.cdp_id, 'role_id': '22', 'base_url': base_url, 'token': result.web_auth_token, 'public_key': null })
                setWLoader(false);
            })
            .catch((error) => console.error(error));
    }
    const [countdown, setCountdown] = useState(30);

    useEffect(() => {
        let interval = null;

        if (selectBtn === 4 && countdown > 0) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [selectBtn, countdown]);
    const [forgotPass, setForgotpass] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState({
        newpassword: false,
        cnewpassword: false,
    });



    const togglePasswordVisibility = (field) => {
        setPasswordVisible({
            ...passwordVisible,
            [field]: !passwordVisible[field],
        });
    };
    const [validationPoints, setValidationPoints] = useState({});
    const validatePassword = (password) => {
        const validations = {
            length: password.length >= 8,
            number: /\d/.test(password),
            lowercase: /[a-z]/.test(password),
            uppercase: /[A-Z]/.test(password),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password)
        };
        setValidationPoints(validations);
    };
    const [err, setError] = useState(false);
    function capitalizeEachWord(inputString) {
        return inputString.replace(/\b\w/g, match => match.toUpperCase());
    }
    return (
        <div className="login_container">
            <div className="login_div">
                <div className="left" style={{ backgroundColor: loginpageData.bg_color }}>
                    <img style={{ height: '50%' }} src={loginpageData.content_img} alt=""></img>
                </div>
                <div className="right">
                    <div className="close" style={{ backgroundColor: loginpageData.bg_color }}
                        onClick={() => {
                            setOpenLogin(false);
                        }}>X</div>
                    <div className="right_sec">
                        {(selectBtn == 1 || selectBtn == 0) && <div className="row1">
                            <div className="rtbn" style={{
                                border: `1px solid ${loginpageData.bg_color}`,
                                backgroundColor: selectBtn == 1 ? loginpageData.bg_color : 'white',
                                color: selectBtn == 1 ? 'white' : loginpageData.bg_color
                            }}
                                onClick={() => { setSelectedBtn(1) }}>Registration</div>
                            <div className="rtbn" style={{
                                border: `1px solid ${loginpageData.bg_color}`,
                                backgroundColor: selectBtn == 0 ? loginpageData.bg_color : 'white',
                                color: selectBtn == 0 ? 'white' : loginpageData.bg_color
                            }}
                                onClick={() => { setSelectedBtn(0) }}>Log In</div>

                        </div>}
                        {selectBtn == 0 && <>
                            <div className="inputfld"><img alt="icon"
                                style={{ height: '30px' }}
                                src="https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/Register/name_ico.png"></img>
                                <input className="loginInput" type='email' placeholder="Email or Mobile" value={formData.email}
                                    onChange={(e) => {
                                        updateFormData('email', e.target.value)
                                    }}></input>
                                {errmsg?.includes('Email or Mobile') && <p className="error">{errmsg}</p>}
                            </div>

                            <div className="inputfld"><img alt="icon"
                                style={{ height: '30px' }}
                                src="https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/Register/pwd_ico.png"></img>
                                <input className="loginInput" type='password' placeholder="Password" value={formData.password}
                                    onChange={(e) => {
                                        updateFormData('password', e.target.value)
                                    }}></input>
                                {errmsg?.includes('Invalid Password.') && <p className="error">{errmsg}</p>}
                            </div>
                            <div className="singhContainer">
                                <div className="signinbtn" style={{ backgroundColor: formData.email && formData.password ? loginpageData.bg_color : 'gray' }}
                                    onClick={() => {
                                        console.log(formData)
                                        if (formData.email && formData.password) {
                                            login();
                                        }
                                    }}>
                                    Sign in</div>
                            </div>
                            <div className="singhContainer">
                                <div className="signinbtn forgot" style={{ color: loginpageData.bg_color }}
                                    onClick={() => {
                                        setSelectedBtn(2)
                                        setForgotpass(true)
                                    }}>
                                    Forgot password?</div>
                            </div>
                            <div className="singhContainer">
                                <div className="rtbn" style={{
                                    border: `1px solid ${loginpageData.bg_color}`,
                                    color: loginpageData.bg_color
                                }}
                                    onClick={() => {
                                        setSelectedBtn(3)
                                        setForgotpass(false)
                                    }}
                                >Login with OTP</div>
                            </div>
                        </>}
                        {selectBtn == 1 && <>
                            <div className="inputfld"><img alt="icon"
                                style={{ height: '30px' }}
                                src="https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/Register/name_ico.png"></img>
                                <input className={`loginInput ${err && formData.firstname == '' ? 'redBottomBorder' : ''}`} type='text' placeholder="First Name" value={formData.firstname}
                                    onChange={(e) => {
                                        updateFormData('firstname', capitalizeEachWord(e.target.value))
                                    }}></input>
                                <input className="loginInput" type='text' placeholder="Last Name" value={formData.lastname}
                                    onChange={(e) => {
                                        updateFormData('lastname', capitalizeEachWord(e.target.value))
                                    }}></input>
                            </div>
                            <div className="inputfld" style={{ display: 'flex', alignItems: 'center' }} >
                                <div style={{ width: '70%', display: 'flex', alignItems: 'center' }}><img alt="icon"
                                    style={{ height: '30px' }}
                                    src="https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/Register/gender_ico.png"></img>
                                    {/* <div> */}
                                    <label>
                                        <input type="radio" name="gender" value="0" checked={formData.gender === '0'} onChange={(e) => updateFormData('gender', e.target.value)} />
                                        <span style={{ padding: '0px 10px' }}>M</span>
                                    </label>
                                    {/* </div> */}
                                    {/* <div> */}
                                    <label>
                                        <input type="radio" name="gender" value="1" checked={formData.gender === '1'} onChange={(e) => updateFormData('gender', e.target.value)} />
                                        <span style={{ padding: '0px 10px' }}>F</span>
                                    </label>
                                    {/* </div> */}
                                </div>

                                <div style={{ width: '60%', display: 'flex', alignItems: 'center' }}>
                                    <img alt="icon"
                                        style={{ height: '30px' }}
                                        src="https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/Register/dob_ico.png"></img>
                                    <input className={`loginInput ${err && formData.age == '' ? 'redBottomBorder' : ''}`} type='number' placeholder="Years" value={formData.age}
                                        onChange={(e) => {
                                            updateFormData('age', e.target.value)
                                        }}></input></div>
                            </div>
                            <div className="inputfld"><img alt="icon"
                                style={{ height: '30px' }}
                                src="https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/Register/mobile_ico.png"></img>
                                <select className="countryCode" value={formData.countryCode} onChange={(e) => updateFormData('countryCode', e.target.value)}>
                                    {countryCodes.map((code) => (
                                        <option key={code.code} value={code.code}>{code.code}</option>
                                    ))}
                                </select>
                                <input className={`loginInput ${err && formData.number == '' ? 'redBottomBorder' : ''}`} type='tel' placeholder="Phone" value={formData.number}
                                    onChange={(e) => {
                                        updateFormData('number', e.target.value)
                                    }}></input>
                            </div>
                            <div className="inputfld"><img alt="icon"
                                style={{ height: '30px' }}
                                src="https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/Register/mail_icon.png"></img>
                                <input className={`loginInput ${err && formData.email == '' ? 'redBottomBorder' : ''}`} type='email' placeholder="Email" value={formData.email}
                                    onChange={(e) => {
                                        updateFormData('email', e.target.value)
                                    }}></input></div>


                            <div className="singhContainer">
                                <div className="rtbn" style={{
                                    border: `1px solid ${loginpageData.bg_color}`,
                                    color: loginpageData.bg_color
                                }}
                                    onClick={() => {
                                        if (formData.email && formData.number && formData.firstname && formData.gender && formData.age) {
                                            setError(false);
                                            createUserAccount()
                                        } else {
                                            setError(true);
                                            toast.error("Fill all required fields", {
                                                position: "top-right",
                                                autoClose: 6000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                            })
                                        }

                                    }}
                                >Submit</div>
                            </div>
                        </>}
                        {(selectBtn == 2 || selectBtn == 3) && <>
                            <div className="forgotText">We will send you a One Time Password
                                on your Mobile/Email</div>
                            <div className="inputfld">
                                <input className="loginInput" type='text' placeholder="Email or Mobile" value={formData.email}
                                    onChange={(e) => {
                                        updateFormData('email', e.target.value)
                                    }}></input>
                            </div>
                            <div className="singhContainer">
                                <div className="signinbtn" style={{ backgroundColor: formData.email ? loginpageData.bg_color : 'gray' }}
                                    onClick={() => {
                                        if (selectBtn == 3 && formData.email) {
                                            loginwithOTP();
                                        } else if (selectBtn == 2 && formData.email) {
                                            forgotOTP()
                                        }
                                    }}>
                                    Get OTP</div>
                            </div>
                        </>}
                        {(selectBtn == 4) && <>
                            <div className="forgotText">Please enter OTP sent to your *******{formData.email.length > 10 ? formData.email.slice(-formData.email.length + 3) : formData.email.slice(-3)}</div>
                            <div className="inputfld">
                                <input className="loginInput" type='text' placeholder="Enter OTP" value={formData.otp}
                                    onChange={(e) => {
                                        updateFormData('otp', e.target.value)
                                    }}></input>
                            </div>
                            <div className="singhContainer">
                                <div className="signinbtn" style={{ backgroundColor: formData.otp.length >= 6 ? loginpageData.bg_color : 'gray' }}
                                    onClick={() => {
                                        if (selectBtn == 4 && formData.otp.length >= 6) {
                                            if (forgotPass) {   //here we are checking for the reset password
                                                verify_otp();
                                            } else if (formData.otp == OTP) {
                                                setWLoader(true);
                                                // const path = `${base_url}/home?login=${formData.email || formData.number}&otp=${OTP}`;
                                                const path = `https://master.d2mp9977g7at58.amplifyapp.com/cdp_master?cdp_id=${rediObject.cdp_id}&token=${rediObject.token}&role_id=${rediObject.role_id}&base_url=${rediObject.base_url}&public_key=${rediObject.public_key}`;
                                                console.log("path is ", path);
                                                window.location.href = path;
                                                setOpenLogin(!openLogin);
                                                setTimeout(() => {
                                                    setWLoader(false);
                                                }, 110000)
                                            } else {
                                                toast.error("Invalid OTP", {
                                                    position: "top-right",
                                                    autoClose: 6000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "light",
                                                })
                                            }
                                        }
                                    }}>
                                    Verify</div>
                            </div>
                            <div className="singhContainer">
                                <div className="forgotText">Did NOT receive the OTP?</div>
                                <div className="signinbtn" style={{
                                    backgroundColor: countdown == 0 ? loginpageData.bg_color : 'white',
                                    color: countdown == 0 ? 'white' : loginpageData.bg_color,
                                    height: '33px', padding: '0px 3px', fontWeight: 100,
                                    border: `1px solid ${loginpageData.bg_color}`
                                }}
                                    onClick={() => {
                                        if (selectBtn == 4 && countdown == 0) {
                                            setCountdown(59);
                                            loginwithOTP();
                                        }
                                    }}>
                                    {countdown > 0 ? `Resend (${countdown})` : "Resend"}</div>
                            </div>
                        </>}
                        {selectBtn == 5 && <>
                            <div className="forgotText">
                                <img alt="" style={{ height: '50px' }} src="https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/Register/pwd_ico.png"></img>
                                <h5 style={{ fontSize: 'x-large' }}>Reset Password</h5>
                            </div>
                            <div className="inputfld">
                                <input className="loginInput" type={passwordVisible.newpassword ? "text" : "password"}
                                    placeholder="New password"
                                    value={formData.newpassword}
                                    onChange={(e) => {
                                        validatePassword(e.target.value);
                                        updateFormData("newpassword", e.target.value)
                                    }
                                    }></input>
                                <img alt="icon"
                                    style={{ height: '18px', cursor: 'pointer' }}
                                    src={passwordVisible.newpassword ? "https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/icons8-eye-100.png" :
                                        "https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/icons8-closed-eye-100.png"}
                                    onClick={() => togglePasswordVisibility("newpassword")}></img>
                            </div>
                            <div className="inputfld">
                                <input className="loginInput" type={passwordVisible.cnewpassword ? "text" : "password"}
                                    placeholder="Confirm new password"
                                    value={formData.cnewpassword}
                                    onChange={(e) => {
                                        validatePassword(e.target.value);
                                        updateFormData("cnewpassword", e.target.value)
                                    }}></input>
                                <img alt="icon"
                                    style={{ height: '18px', cursor: 'pointer' }}
                                    src={passwordVisible.cnewpassword ? "https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/icons8-eye-100.png" :
                                        "https://nanohealth-images.s3-ap-southeast-1.amazonaws.com/icons8-closed-eye-100.png"}
                                    onClick={() => togglePasswordVisibility("cnewpassword")}></img>
                            </div>

                            <ul className="passwordvalidation">
                                <li style={{ color: validationPoints.length ? 'green' : 'red' }}>Password must be at least 8 characters</li>
                                <li style={{ color: validationPoints.lowercase ? 'green' : 'red' }}>Includes one lowercase letter</li>
                                <li style={{ color: validationPoints.uppercase ? 'green' : 'red' }}>Includes one uppercase letter</li>
                                <li style={{ color: validationPoints.specialChar ? 'green' : 'red' }}>Includes one special character</li>
                                <li style={{ color: validationPoints.number ? 'green' : 'red' }}>Includes one number</li>
                            </ul>
                            <div className="singhContainer">
                                <div className="rtbn" style={{
                                    border: `1px solid ${loginpageData.bg_color}`,
                                    color: loginpageData.bg_color
                                }}
                                    onClick={() => {
                                        if (formData.newpassword !== formData.cnewpassword) {
                                            toast.error("Passwords do not match", {
                                                position: "top-right",
                                                autoClose: 6000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                            })
                                        } else if (validationPoints.length && validationPoints.lowercase && validationPoints.uppercase && validationPoints.specialChar && validationPoints.number) {
                                            updatepassword()
                                        } else {
                                            toast.error("Passwords must have required criteria", {
                                                position: "top-right",
                                                autoClose: 6000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                            })
                                        }
                                        // createUserAccount()  after saving this redirect to the login page 0
                                    }}
                                >Submit</div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}