import React, { useState, useContext, useEffect } from 'react';
import { CurrentUserContext } from '../../App.js';
import Loader from '../Loader.js';
import './AddressCom.css';

export default function AddressCom({ setTemF, temF, dataa, setData }) {
    const { base_url, headerData, citiyMaster } = useContext(CurrentUserContext);
    const [fromData, setFromData] = useState(dataa.address);
    const [fieldValidation, setFieldValidation] = useState(dataa.address);
    const [edit, setEdit] = useState(true);
    const [err, setErr] = useState(false);
    const [stateName, setStateName] = useState('');
    const [loader, setLoader] = useState(false);

    async function foo(id) {
        if (id == 1) {
            setStateName('Telangana');
            return;
        }

        setLoader(true);
        const myHeaders = new Headers();
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };
        fetch(`${base_url}/api/v1/master_data/hospital_masters/states?city_master_id=${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setStateName(result.state_nm);
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                console.error(error)
            });
    }
    useEffect(() => { foo(dataa.address.city_master_id) }, [])
    function handleClose() {
        setData(dataa);
        setTemF(false);
    }
    const keySequence = [
        'address_line1',
        'address_line2',
        'area',
        'city_master_id',
        'State',
        'landmark',
        'pin_code',
    ];
    function capitalizeEachWord(inputString) {
        return inputString.replace(/\b\w/g, match => match.toUpperCase());
    }
    function matchLabel(key) {
        switch (key) {
            case 'address_line1':
                return 'Address Line 1';
            case 'address_line2':
                return 'Address Line 2';
            case 'area':
                return 'Area';
            case 'landmark':
                return 'Landmark';
            case 'pin_code':
                return 'Pin code';
            case 'city_master_id':
                return 'City';
            default:
                return key.replace(/_/g, ' ');
        }
    }
    useEffect(() => { console.log(fromData) }, [fromData])
    const handleChangeWS = (key, value) => {
        console.log("key is ", key, "value is ", value);
        setFromData(prevState => ({
            ...prevState,
            [key]: value
        }));

        setFieldValidation(prevState => ({
            ...prevState,
            [key]: !!value
        }));
    };
    function handleSubmitAddress() {
        if (!fieldValidation['address_line1'] || !fieldValidation['area'] || !fieldValidation['pin_code']) {
            setErr(true);
            return;
        }
        const updatedData = {
            ...dataa,
            address: {
                ...fromData
            }
        };
        setData({ ...updatedData });
        setTemF(false);
    }
    return <div className='addressContainer'>
        {loader && <div className='addressContainer'><Loader /></div>}
        <div className='addressbox'>
            <h3 style={{
                marginBottom: '15px', display: 'flex', justifyContent: 'space-between',
                fontWeight: '300', textAlign: 'center', color: 'white',
                background: headerData?.primary_color, padding: '6px'
            }}>
                <span>{''}</span><span id="popupH">{`Address Details`}  </span>
                <span class="cdp_closebtn" style={{ color: headerData.primary_color }} onClick={() => setTemF(false)}> X </span>
            </h3>
            <div className='form_area'>
                {keySequence.map((key) => {
                    if (key === 'address_line1') {
                        return (
                            <div key={key} className="eachContentAddress">
                                <label>{matchLabel(key)}<p className="star">*</p></label>
                                <input
                                    type="text"
                                    value={fromData[key] || ''}
                                    onChange={(e) => handleChangeWS(key, capitalizeEachWord(e.target.value))}
                                    disabled={!edit}
                                    style={{
                                        borderBottom: err ? (fieldValidation[key] && fromData[key]) ? '1px solid gray' : '2px solid red' : '1px solid gray'
                                    }}
                                />
                                {err && !fromData[key] && <span style={{ display: 'block', position: 'relative', color: 'red' }}>{'Fill this section'}</span>}

                            </div>)
                    } if (key === 'area') {
                        return (
                            <div key={key} className="eachContentAddress">
                                <label>{matchLabel(key)}<p className="star">*</p></label>
                                <input
                                    type="text"
                                    value={fromData[key] || ''}
                                    onChange={(e) => handleChangeWS(key, capitalizeEachWord(e.target.value))}
                                    disabled={!edit}
                                    style={{
                                        borderBottom: err ? (fieldValidation[key] && fromData[key]) ? '1px solid gray' : '2px solid red' : '1px solid gray'
                                    }}
                                />
                                {err && !fromData[key] && <span style={{ display: 'block', position: 'relative', color: 'red' }}>{'Fill this section'}</span>}

                            </div>)
                    } if (key === 'pin_code') {
                        return (
                            <div key={key} className="eachContentAddress">
                                <label>{matchLabel(key)}<p className="star">*</p></label>
                                <input
                                    type="number"
                                    value={fromData[key] || ''}
                                    onChange={(e) => {
                                        const newValue = e.target.value.slice(0, 6); // Truncate to 6 digits
                                        handleChangeWS(key, newValue)
                                    }}
                                    disabled={!edit}
                                    style={{
                                        borderBottom: err ? (fieldValidation[key] && fromData[key]) ? '1px solid gray' : '2px solid red' : '1px solid gray'
                                    }}
                                />{fromData[key].length > 0 && fromData[key].length < 6 && <span style={{ display: 'block', position: 'relative', color: 'red' }}>{'Pin code must be 6 digits'}</span>}
                                {err && !fromData[key] && <span style={{ display: 'block', position: 'relative', color: 'red' }}>{'Fill this section'}</span>}

                            </div>)
                    } else if (key == 'State') {
                        return (
                            <div key={key} className="eachContentAddress">
                                <label>{matchLabel(key)}</label>
                                <input
                                    disabled
                                    type="text"
                                    value={stateName}
                                />
                            </div>
                        );
                    } else if (key === 'city_master_id') {
                        return (<div key={key} className="eachContentAddress">
                            <label>{matchLabel(key)}<p className="star">*</p></label>
                            <select
                                key={key}
                                disabled={!edit}
                                style={{
                                    padding: "8px 8px 8px 0px",
                                    marginBottom: "5px",
                                    outline: "none",
                                    border: "none",
                                    borderRadius: "2.7px",
                                    width: "100%",
                                    background: edit
                                        ? "transparent"
                                        : "-internal-light-dark(rgba(239, 239, 239, 1), rgba(59, 59, 59, 1));",
                                    color: "inherit",
                                    borderBottom: "1px solid gray",
                                    cursor: edit !== false ? "pointer" : "default",

                                }}
                                value={fromData[key] || 1}
                                onChange={(e) => {
                                    foo(e.target.value);
                                    handleChangeWS(key, e.target.value);
                                }
                                }
                            >     {citiyMaster?.map((facility) => (
                                <option key={facility.id} value={facility.id}>
                                    {facility.cty_nm}
                                </option>
                            ))}
                            </select>
                        </div>)
                    } else {
                        return (
                            <div key={key} className="eachContentAddress">
                                <label>{matchLabel(key)}</label>
                                <input
                                    type="text"
                                    value={fromData[key] || ''}
                                    onChange={(e) => handleChangeWS(key, e.target.value)}
                                    disabled={!edit}
                                />
                            </div>
                        );
                    }
                })}
            </div>
            <div id="fixed_footerr" className='closebtnaddress' style={{backgroundColor: headerData?.secondary_color }}>
                <button className="BtnC" style={{ background: '#FF852E !important' }} type="button"
                    onClick={handleClose}>Close</button>
                {edit === true ?
                    <button className="BtnS" type="submit" onClick={handleSubmitAddress}>Submit</button> :
                    <button className="BtnS" type="submit" onClick={() => setEdit(true)}>Edit</button>
                }
            </div>
        </div>
    </div>
}