import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loader() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress  size={65}  color="primary" />
    </Box>
  );
}

export default Loader;
