import React, { createContext, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Dynamic_Website from './DynacmicWebsite/DyanmicWebsite.js';
export const CurrentUserContext = createContext(null);


function App() {
  const [Complete_website_data, setCompleteWebsiteData] = useState('');
  const [websiteData, setWebsiteData] = useState([]);
  const [base_url, setBaseUrl] = useState('https://customer.nanohealth.in');
  const [headerData, setData] = useState(null);
  const [wLoader, setWLoader] = useState(true);
  const [urID, setURid] = useState(null);
  const [citiyMaster, setCityMaster] = useState([]);
  const [openCDP, setOpenCDP] = useState(false);
  const [selectBtn, setSelectedBtn] = useState(0);
  const [openLogin, setOpenLogin] = useState(false);
  const [cdpDetails, setCdpDetails] = useState({
    "id": null,
    "name": "",
    "desc": "",
    "contact_detail_id": null,
    "address_detail_id": null,
    "web_logo_url": "",
    "hme_bgrnd_img": "",
    "app_logo_url": "",
    "dummy1": null,
    "dummy2": null,
    "dummy3": null,
    "is_active": true,
    "archived_by_id": null,
    "archive": false,
    "archived_at": null,
    "trmntn_dt": null,
    "patient_id_format": "{prefix: \"NHIN\", id_format: \"DateTime.now.strftime('%Q')\"}",
    "app_link_url": "",
    "razorpay_id": "",
    "domain_link": "",
    "dob": "01Jan96",
    "address": {
      "id": null,
      // "user_id": "",
      "address_type": "",
      "address_kind": "",
      "address_line1": "",
      "address_line2": "",
      "area": "",
      "landmark": "",
      "city_master_id": 1,
      "pin_code": "",
      "rfq_corp_master_id": null,
      "corp_master_id": null,
      "patient_master_id": null,
      "diagnostic_lab_master_id": null,
      "updates_json": null,
      "city_master": {
      }
    },
    "contact": {
      "id": null,
      "provider_name": null,
      "provider_id": 1,
      "frst_nm": null,
      "lst_nm": null,
      "phn_nmbr_typ": null,
      "nmbr": "",
      "fax": null,
      "country_master_id": null,
      "archive": false,
      "archived_by_id": null,
      "archived_at": null,
      "dummy1": null,
      "dummy2": null,
      "dummy3": null,
      "rfq_corp_master_id": null,
      "corp_master_id": null,
      "email": "",
      "patient_master_id": null,
      "updates_json": null
    }
  })
  const [auth_token, setAuthToken] = useState(null);
  useEffect(() => {
    const currentURL = window.location.origin;
    const domainName = currentURL.split('/')[2];           //here we are removing http:// from the urls
    document.title = domainName;                            //here we are updating the title
    setCompleteWebsiteData(domainName);
  }, []);
  return (
    <CurrentUserContext.Provider
      value={{
        Complete_website_data, setCompleteWebsiteData, websiteData, setWebsiteData
        , headerData, setData, wLoader, setWLoader, base_url, setBaseUrl, cdpDetails, setCdpDetails
        , citiyMaster, setCityMaster, openCDP, setOpenCDP, urID, setURid, selectBtn, setSelectedBtn
        , openLogin, setOpenLogin, auth_token, setAuthToken
      }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dynamic_Website />} />
          <Route path="/:name/:id" element={<Dynamic_Website />} />
          <Route path="/:name/:id/:blogName/:blogId" element={<Dynamic_Website />} />
        </Routes>
      </BrowserRouter>
    </CurrentUserContext.Provider>);
}

export default App;
