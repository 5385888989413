import React, { useState, useContext, useEffect } from 'react';
import { CurrentUserContext } from '../../App.js';
import editTem from '../ICons/tempEdit.png';
import TextField from '@mui/material/TextField';
import Loader from '../Loader.js';
import './PhoneCom.css';


export default function NestedModal({ setPhoneF, phnF, dataa, setData }) {
    const { headerData, token, BaseURl } = useContext(CurrentUserContext);
    const [open, setOpen] = useState(phnF);
    const [openEdit, setOpenEdit] = useState(false);  // here we need to update the flag to open existing model
    const [contactDetails, setContactDetails] = useState(null);
    const [restoreContact, setRestoreContact] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const handleAdd = () => {
        if (contactDetails.some((obj) => obj.id === null)) {
            return;
        }
        const newData = contactDetails.map((obj) => {
            return { ...obj, editing: false };
        });
        const newObj = {
            id: null,
            nmbr: "",
            email: "",
            editing: true,
        };
        setContactDetails([...newData, newObj]);
    };
    const [errValidation, setErrValidation] = useState(false);
    const handleSave = (id, nmbr, email) => {
        const newData = contactDetails.map((obj) => {
            if (obj.id === id) {
                return { ...obj, nmbr, email };
            }
            return obj;
        });
        setContactDetails(newData);
    };

    const handleEdit = (id) => {
        console.log("Id in the edit number ", id);
        const newData = restoreContact.map((obj) => {
            if (obj.id === id) {
                return { ...obj, editing: true };
            }
            return { ...obj, editing: false };
        });
        const exceptNull = newData.filter((obj) => obj.id !== null);
        console.log("Updated contactdetails", exceptNull)
        // setContactDetails(exceptNull);
        setContactDetails(newData);
    };

    const handleSubmit = (id) => {
        setLoading(true);
        const newData = contactDetails.filter((obj) => obj.id === id);
        if (newData[0].nmbr == '' || newData[0].email == '' || newData[0].nmbr.length < 10) {
            console.log("In the validation seciton");
            setErrValidation(true);
            setLoading(false);
            return;
        } else {
            setErrValidation(false);
        }
        console.log("newObject updated or created", { ...newData[0], provider_name: getType(dataa), provider_id: dataa.id });
        sendPostRequest({
            nmbr: newData[0].nmbr, email: newData[0].email,
            id: newData[0].id, provider_name: getType(dataa), provider_id: dataa.id
        });
    };

    function getType(dataa) {
        if ('lab_name' in dataa) {
            return 'DiagnosticLabMasters';
        } else if ('pharmacy_name' in dataa) {
            return 'PharmacyMasters';
        } else if ('hsptl_nm' in dataa) {
            return 'HospitalMaster';
        } else {
            return null;
        }
    }

    const fetchProviderContacts = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Token", token);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${BaseURl}/api/v1/master_data/hospital_masters/provider_contacts?provider=${getType(dataa)}&provider_id=${dataa.id}`,
                requestOptions
            );
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    };

    async function sendPostRequest(obj) {
        setLoading(true);
        const modifiedObj = {
            nmbr: obj.nmbr,
            email: obj.email,
            provider_name: obj.provider_name,
            provider_id: obj.provider_id
        };

        if (obj.id !== null) {
            modifiedObj.id = obj.id;
        }

        const myHeaders = new Headers();
        myHeaders.append("Token", token);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(modifiedObj),
            redirect: "follow"
        };

        try {
            const response = await fetch(
                `${BaseURl}/api/v1/master_data/hospital_masters/create_provider_contacts`,
                requestOptions
            );
            const result = await response.text();
            const resultD = await fetchProviderContacts();
            setContactDetails(resultD);
            setRestoreContact(resultD);
            setLoading(false);
            console.log(result);
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            setError(null);
            try {
                const result = await fetchProviderContacts();
                setContactDetails(result);
                setRestoreContact(result);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        if (!dataa.hasOwnProperty('hme_bgrnd_img')) {
            loadData();
        } else {
            console.log("Data in useEffect", dataa)
            setContactDetails([dataa.contact]);
            setRestoreContact([dataa.contact]);
            setLoading(false);
        }

    }, []);
    const handleClose = () => {
        setOpen(false);
        setPhoneF(false);
    };
    const handleChange = (key, value) => {
        setData(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    return (
        <div className='phoneContainer'>
            <div className='phonebox'>
                <h3 style={{
                    marginBottom: '15px', display: 'flex', justifyContent: 'space-between',
                    fontWeight: '300', textAlign: 'center', color: 'white',
                    background: headerData?.primary_color, padding: '6px'
                }}>
                    <span>{''}</span><span id="popupH">{`Contact Details`}  </span>
                    <span>
                    </span>

                </h3>

                {!loading ? <div className='phone_c' >
                    {contactDetails && contactDetails?.length > 0
                        && <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ color: '#4b89a8' }}><label>Number <p className="star">*</p></label></th>
                                    <th style={{ color: '#4b89a8' }}><label>Email <p className="star">*</p></label></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactDetails.map((obj) => {
                                    console.log("Obj while mapping", obj);
                                    return (
                                        <tr key={obj.id}>
                                            {obj.editing||obj.id==null ? (
                                                <>
                                                    <td>
                                                        <input
                                                            className='inputStyle'
                                                            type="number"
                                                            value={obj.nmbr}
                                                            onChange={(e) => {
                                                                if (e.target.value.length === 11) return;
                                                                handleSave(obj.id, e.target.value, obj.email);
                                                            }}
                                                        />
                                                        {errValidation && obj.nmbr.length < 10 && (
                                                            <span style={{ color: 'red', display: 'block' }}>
                                                                {obj.nmbr.length < 1 ? 'Fill this section' : 'Number should be 10 digits'}
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <input
                                                            className='inputStyle'
                                                            type="email"
                                                            value={obj.email}
                                                            onChange={(e) => handleSave(obj.id, obj.nmbr, e.target.value)}
                                                        />
                                                        {errValidation && obj.email.length < 1 && (
                                                            <span style={{ color: 'red', display: 'block' }}>Fill this section</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <button className='BtnS'
                                                            onClick={() => {
                                                                if (dataa.hasOwnProperty('hme_bgrnd_img')) {
                                                                    if (contactDetails[0].nmbr === '' || contactDetails[0].email === '' || contactDetails[0].nmbr.length < 10) {
                                                                        setErrValidation(true);
                                                                        return;
                                                                    }
                                                                    const updatedData = {
                                                                        ...dataa,
                                                                        contact: {
                                                                            ...dataa.contact,
                                                                            nmbr: contactDetails[0].nmbr,
                                                                            email: contactDetails[0].email
                                                                        }
                                                                    };
                                                                    setData({ ...updatedData });
                                                                    handleClose();
                                                                } else {
                                                                    handleSubmit(obj.id);
                                                                }
                                                            }}
                                                        >
                                                            Save
                                                        </button>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td>
                                                        <input
                                                            className='inputStyle'
                                                            type="number"
                                                            value={obj.nmbr}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className='inputStyle'
                                                            type="email"
                                                            value={obj.email}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <img
                                                            style={{ height: '25px', width: '25px', cursor: 'pointer' }}
                                                            alt='editBtn'
                                                            onClick={() => handleEdit(obj.id)}
                                                            src={editTem}
                                                        />
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>}
                    {!dataa.hasOwnProperty('hme_bgrnd_img') && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <button onClick={handleAdd}>Add +</button></div>}
                </div> : <div style={{ display: 'flex', justifyContent: 'center' }}><Loader /></div>}
                {<div className='closeBTnstyle' style={{ backgroundColor: headerData?.secondary_color }}>

                    <button className="BtnC" type="submit" onClick={handleClose}>Close</button>
                </div>}
            </div>
        </div>
    );
}
